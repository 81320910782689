(function () {
    let togglePassword = document.querySelector(".toggle-password");
    if(togglePassword) 
        togglePassword.onclick = () => {
            const newClassName = togglePassword
                .className
                .split(" ")
                .map(x => {
                    if (x === "fa-eye") {
                        return "fa-eye-slash";
                    } else if (x === "fa-eye-slash") {
                        return "fa-eye";
                    } else {
                        return x;
                    }
                })
                .join(" ");
            togglePassword.className = newClassName;
            const input = document.querySelector("#password");
            const newType = input.type === "password" ? "text" : "password";
            input.type = newType;
        }

    let togglePasswordConfirmation = document.querySelector(".toggle-password-confirmation");
    if(togglePasswordConfirmation)
        togglePasswordConfirmation.onclick = () => {
            const newClassName = togglePasswordConfirmation
                .className
                .split(" ")
                .map(x => {
                    if (x === "fa-eye") {
                        return "fa-eye-slash";
                    } else if (x === "fa-eye-slash") {
                        return "fa-eye";
                    } else {
                        return x;
                    }
                })
                .join(" ");
            togglePasswordConfirmation.className = newClassName;
            const input = document.querySelector("#password-confirmation");
            const newType = input.type === "password" ? "text" : "password";
            input.type = newType;
        }
})();